import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { GlobalUserInfoContext } from '../../hooks/useGlobalUserInfo';

const fetch = axios.create({
  baseURL: '/board',
  timeout: 30000,
});

const RouteInterceptor = ({ children }) => {
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const response = await fetch.get('/auth/user_info');
        const { code, data: userInfo } = response.data;
        if (code === 0) {
          setUserInfo(userInfo);
        }
      } catch (error) {
        console.error('Error getUserInfo', error);
      }
    };

    getUserInfo();
  }, []);

  return (
    <GlobalUserInfoContext.Provider value={{ userInfo }}>{children}</GlobalUserInfoContext.Provider>
  );
};

export default RouteInterceptor;
