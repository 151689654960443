/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */
import React from 'react';
import RouteInterceptor from '@components/RouteInterceptor';
import '@styles/global.css';
import '@styles/gatsby.css';

// ! 路由拦截，针对登录中心完成登录流程后跳转回来的情况
export const wrapPageElement = ({ element, props }) => {
  return <RouteInterceptor {...props}>{element}</RouteInterceptor>;
};
